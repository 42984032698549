import PropTypes from "prop-types"
import React from "react"
import LogoImage from "./logo_image"
import { useStaticQuery, graphql } from "gatsby"
import * as moment from "moment"

const Header = ({ siteTitle }) => {

  const data = useStaticQuery(graphql`
  query ConferenceDatesQ {
    contentJson {
      conferenceInfo {
        startDate,
        endDate,
        city,
        country,
        cfp {
          cfpLink {
            label
            papercallLink
          }
        }
      }
    }
  }
`)
  
  return (
  <section className="hero is-bold is-primary">
    <div className="hero-body has-text-centered">
    <div className="container">
      <div className="columns">
        <div className="column is-full">
          <LogoImage />
        </div>
        </div>
        <div className="columns">
          <div className="column is-full">
            <h1 className="is-size-2 is-family-primary has-text-weight-semibold">flatMap(Oslo)</h1>
            </div>
          </div>
          <div className="column is-full">
            <div className="has-text-centered is-size-4 conferenceDateHeader">
              {moment(data.contentJson.conferenceInfo.startDate).date()} - {moment(data.contentJson.conferenceInfo.endDate).date()} {moment(data.contentJson.conferenceInfo.startDate).format("MMMM")} {moment(data.contentJson.conferenceInfo.startDate).year()}
            </div>
            <div className="has-text-centered is-size-5 conferenceDateHeader">
              {data.contentJson.conferenceInfo.city}, {data.contentJson.conferenceInfo.country}
            </div>
          </div>
          </div>
    </div>
  </section>
)
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
