import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import LogoImage from "../components/logo_image"
import SEO from "../components/seo"
import Header from "../components/header"
import Timeline from "../components/timeline"
import CfpLink from "../components/cfplink"
import { useStaticQuery, graphql } from "gatsby"
import * as moment from "moment"
import "./styles.scss"
import styled from "@emotion/styled"

const IndexPage = () => {

  const data = useStaticQuery(graphql`
    query ConferenceInfoQuery {
      site {
        siteMetadata {
          title
        }
      }
      contentJson {
        conferenceInfo {
          startDate,
          endDate,
          city,
          country,
          cfp {
            cfpLink {
              label
              papercallLink
            }
          }
          tickets {
            ticketsLink {
              label
              href
            }
          }
          timeline {
            label
            dates {
              date
              label
            }
          }
        }
      }
    }
  `)

  const OrangeDiv = styled.div`
    color: #4694A6;
  `

  const BackDrop = styled.div`
    background-color: white;
    margin-top: 0.8rem;
  `

  return (
    <Layout>
      <SEO title="Home" />
      <Header siteTitle={data.site.siteMetadata.title} />
      <div className="section">
        <BackDrop className="container content">

          <div className="columns">
            <div className="column">
              <h2 className="is-size-1 is-family-primary has-text-weight-black has-text-centered">
                Due to the COVID-19 virus we regret to inform you that flatMap(Oslo) 2020 is cancelled.
              </h2>
              <p className="is-size-2">
                We feel that the contagious nature of the virus means it's not responsible to go ahead with the conference. 
                We will reimburse all purchased tickets in the coming days.
              </p>
              <p>
                If you have any questions, please don't hesitate to contact us via email <a href='mailto:flatmap@arktekk.no'>flatmap@arktekk.no</a>.
              </p>
            </div>
          </div>
        </BackDrop>
      </div>
    </Layout>
  )
}

export default IndexPage
